import * as React from 'react';
import { WidgetApp } from './components/WidgetApp/WidgetApp';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import { overrideStyleProps } from '../Shared/appKeys/overrides';
import { withLeanStyles } from '@wix/native-components-infra';

export const ComponentNoSentry = withStyles(WidgetApp, {
  cssPath: ['widgetComponent.stylable.bundle.css'],
});

export const ComponentLeanCssNoSentry = withLeanStyles(WidgetApp, {
  cssPath: ['widgetComponent.stylable.bundle.css'],
});

export const ComponentNoSentryWithAppSettingData = (props) => {
  return <ComponentNoSentry {...overrideStyleProps(props)} />;
};

export const ComponentLeanCssNoSentryWithAppSettingData = (props) => {
  return <ComponentLeanCssNoSentry {...overrideStyleProps(props)} />;
};
