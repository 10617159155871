import * as React from 'react';
import { BiLoggerDriver } from '../../adapters/reporting/bi-logger/bi-logger-driver';

export interface BiLoggerContext {
  biLoggerDriver: BiLoggerDriver;
}

export interface BiLoggerProps {
  biLoggerDriver?: BiLoggerDriver;
}

const biLoggerContext = React.createContext<BiLoggerContext>(null);

export const BiLoggerContextProvider = biLoggerContext.Provider;

export const BiLoggerContextConsumer = biLoggerContext.Consumer;

export const withBiLoggerContext = <TProps extends object>(
  WrappedComponent: React.ComponentType<TProps>,
) =>
  class withBiLoggerContextComponent extends React.Component<
    TProps & BiLoggerProps
  > {
    render() {
      return (
        <BiLoggerContextConsumer>
          {(context: BiLoggerContext) => {
            const biProps = {
              biLoggerDriver: context.biLoggerDriver,
            };
            return <WrappedComponent {...this.props} {...biProps} />;
          }}
        </BiLoggerContextConsumer>
      );
    }
  };
