import * as React from 'react';
import { st, classes } from './OfferingMoreInfoLabel.st.css';
import { TextButton } from 'wix-ui-tpa/TextButton';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

export interface OfferingMoreInfoLabelProps extends RunningEnvironmentProps {
  onClick?: Function;
  children?: any;
  dataHook: string;
}

const OfferingMoreInfoLabelComponent = (props: OfferingMoreInfoLabelProps) => {
  const { onClick, children, runningEnvironment, dataHook } = props;
  const { isMobile } = runningEnvironment;

  return (
    <div className={classes.textButtonWrapper}>
      <TextButton
        onClick={onClick as any}
        className={st(classes.root, { disableFontSize: isMobile, isMobile })}
        data-hook={dataHook}
      >
        {children}
      </TextButton>
    </div>
  );
};

export const OfferingMoreInfoLabel = withRunningEnvironmentContext(
  OfferingMoreInfoLabelComponent,
);
