import * as imageSDK from '@wix/image-client-api/dist/imageClientSDK';
import { ImageDto } from '@wix/bookings-uou-domain';

export const getFillImageUrl = (
  media: ImageDto,
  imageDimensions: { width?: number; height?: number },
  focalPoint?: { x: number; y: number },
) => {
  return media
    ? imageSDK.getScaleToFillImageURL(
        media.relativeUri,
        media.width,
        media.height,
        imageDimensions.width,
        imageDimensions.height,
        { focalPoint },
      )
    : null;
};

export const getFitImageUrl = (
  media: ImageDto,
  imageDimensions: { width?: number; height?: number },
) => {
  return media
    ? imageSDK.getScaleToFitImageURL(
        media.relativeUri,
        media.width,
        media.height,
        imageDimensions.width,
        imageDimensions.height,
      )
    : null;
};

// export const getDevicePixelRatio = () =>
//   (typeof window !== 'undefined' && window.devicePixelRatio) || 1;
