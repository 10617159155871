function stripAgentVersion(agentVersion) {
  return parseInt(agentVersion.split('/')[1].split('.')[0], 10);
}

function getVersionForAgent(agent) {
  const regexPattern = new RegExp(`${agent}\\/[\\d.]+`, 'g');
  const agentVersion = navigator.userAgent.match(regexPattern);
  return agentVersion && stripAgentVersion(agentVersion[0]);
}

export function isNonGridUserAgent() {
  return isCorruptedEdgeVersion() || isCorruptedFireFoxVersion();
}

function isCorruptedEdgeVersion() {
  const edgeVersion = getVersionForAgent('Edge');
  return !!edgeVersion && edgeVersion < 17;
}

function isCorruptedFireFoxVersion() {
  const firefoxVersion = getVersionForAgent('Firefox');
  return !!firefoxVersion && firefoxVersion < 60;
}
