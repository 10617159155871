import * as React from 'react';
import { Tabs } from 'wix-ui-tpa/Tabs';
import { st, classes } from './TabsCategories.st.css';
import {
  SKIN,
  ALIGNMENT,
  VARIANT,
} from 'wix-ui-tpa/dist/src/components/Tabs/constants';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../../context/running-environment-context';
import { CategoriesProps } from '../Categories';

interface TabsCategoriesProps
  extends RunningEnvironmentProps,
    CategoriesProps {}

class TabsCategoriesComponent extends React.PureComponent<TabsCategoriesProps> {
  constructor(props: TabsCategoriesProps) {
    super(props);
    this.onCategoryTabSelected = this.onCategoryTabSelected.bind(this);
  }

  onCategoryTabSelected(newSelectedCategoryIndex) {
    this.props.onCategorySelected(newSelectedCategoryIndex);
  }

  render() {
    const {
      categoriesViewModel,
      categoriesItems,
      selectedCategoryIndex,
      runningEnvironment,
    } = this.props;
    const { alignment, variant } = categoriesViewModel;
    const items = categoriesItems.map((category) => {
      return {
        title: category.title,
      };
    });
    const tabsProps = {
      items,
      onTabClick: (newSelectedCategoryIndex) =>
        this.onCategoryTabSelected(newSelectedCategoryIndex),
      activeTabIndex: selectedCategoryIndex,
      skin: SKIN.fitUnderline,
      alignment: (alignment as unknown) as ALIGNMENT,
      variant: variant as VARIANT,
    };
    const { isMobile } = runningEnvironment;

    return (
      <Tabs
        data-hook="offerings-categories"
        className={st(classes.root, { isMobile, disableFontSize: isMobile })}
        {...tabsProps}
      />
    );
  }
}

export const TabsCategories = withRunningEnvironmentContext(
  TabsCategoriesComponent,
);
