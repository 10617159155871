import * as React from 'react';
import { st, classes } from './EmptyStateView.st.css';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { translate, TransProps } from 'react-i18next';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../context/running-environment-context';
import { TProp } from '../../../Shared/context/trans-prop';

interface EmptyStateProps extends RunningEnvironmentProps, TProp {}

class EmptyStateViewComponent extends React.PureComponent<
  EmptyStateProps & TransProps
> {
  render() {
    const { t, runningEnvironment } = this.props;
    const { isMobile } = runningEnvironment;
    return (
      <div
        data-hook="empty-state-wrapper"
        className={st(classes.root, { mobile: isMobile })}
      >
        <div className={classes.container}>
          <Text typography={TYPOGRAPHY.smallTitle}>
            {t('empty-state.text')}
          </Text>
        </div>
      </div>
    );
  }
}

export default withRunningEnvironmentContext<EmptyStateProps>(
  translate(null, { wait: true })(EmptyStateViewComponent as any),
);
