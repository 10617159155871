import * as i18next from 'i18next';

export default function i18n(locale, translation) {
  return i18next.init({
    // Initial translation data
    resources: {
      [locale]: {
        translation,
      },
    },

    // Initial language
    lng: locale,

    // Fallback language
    fallbackLng: 'en',

    // Don't use a key separator (no support for nested translation objects)
    keySeparator: false,

    // Wait for translation data to be available before rendering a component
    wait: true,
  });
}
