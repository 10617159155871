import * as React from 'react';
import { CategoriesViewModel } from '../../../domain/multi-offerings-view-model-factory';
import { DropDownCategories } from './DropDownCategories/DropDownCategories';
import { TabsCategories } from './TabsCategories/TabsCategories';
import { TagsCategories } from './TagsCategories/TagsCategories';
import { CategoryLayoutOptions } from '../../../../Shared/appKeys/SettingsKeys';
import { classes } from './Categories.st.css';
import {
  withExperimentsContext,
  ExperimentsProps,
} from '../../../../Shared/context/experiments-context';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../../context/bi-logger-context';

export interface CategoriesItem {
  title: string;
  id: string;
}

export interface CategoriesProps extends ExperimentsProps, BiLoggerProps {
  categoriesViewModel: CategoriesViewModel;
  categoriesItems: CategoriesItem[];
  onCategorySelected: Function;
  selectedCategoryIndex: number;
}

class CategoriesComponent extends React.PureComponent<CategoriesProps> {
  componentDidMount() {
    const { biLoggerDriver, categoriesViewModel } = this.props;
    // eslint-disable-next-line no-unused-expressions
    biLoggerDriver?.sendAllServicesCategoryExposure?.(
      categoriesViewModel.allServicesCategoryExposureInfo,
    );
  }

  getCategoryLayoutComponent() {
    const { categoriesViewModel, experiments } = this.props;
    switch (categoriesViewModel.layout) {
      case CategoryLayoutOptions.DROPDOWN:
        return DropDownCategories;
      case CategoryLayoutOptions.TAGS:
        return experiments.isCategoryLayoutImprovementDesktopEnabled
          ? TagsCategories
          : TabsCategories;
      case CategoryLayoutOptions.TABS:
      default:
        return TabsCategories;
    }
  }

  render() {
    const CategoryLayoutComponent = this.getCategoryLayoutComponent();
    return (
      <div className={classes.root}>
        <CategoryLayoutComponent {...this.props} />
      </div>
    );
  }
}

export default withExperimentsContext(withBiLoggerContext(CategoriesComponent));
