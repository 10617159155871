import { isMobileFromFormFactor } from '../utils';
import {
  mergeMobileDefaultsIntoDesktop,
  mergeMobileSettingsIntoDesktop,
} from './mobileToDesktopKeys';

const hexToRGBA = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const normalizeOpacity = (opacity) => (opacity === 0 ? 0 : opacity || 100);
const fixFractionalOpacityToInt = (opacity) =>
  opacity && opacity <= 1 ? opacity * 100 : opacity;
const getOpacityFromRgba = (rgba) => rgba.split(',')[3].replace(')', '');
const isFont = (data) => data && !!data.editorKey;
const isColor = (data) => data && !!data.themeName;

function overrideSiteFontIfNeeded(
  font,
  siteTextPresets,
  isSettingsPanel,
  isServiceListSettingsThemeChange,
) {
  if (isFont(font)) {
    const siteFont = Object.values(siteTextPresets).find(
      (textPreset: any) => textPreset.editorKey === font.editorKey,
    ) as any;

    const overloadedFont = {
      ...siteFont,
      ...font,
      family: siteFont.fontFamily,
    };

    overloadedFont.style = {
      bold: overloadedFont.weight === 'bold',
      italic: overloadedFont.style === 'italic',
    };

    const stringToTrim = 'font:';

    if (
      overloadedFont.value &&
      overloadedFont.value.indexOf(stringToTrim) === 0
    ) {
      overloadedFont.value = '';
    }

    if (isServiceListSettingsThemeChange) {
      return overloadedFont;
    } else {
      return isSettingsPanel ? overloadedFont : JSON.stringify(overloadedFont);
    }
  }
  return font;
}

function overrideSiteColorIfNeeded(color, siteColors) {
  if (isColor(color)) {
    const siteColor = siteColors.find(
      ({ reference, name }) =>
        reference === color.themeName || name === color.themeName,
    );
    if (!color.opacity && color.value && color.value.includes('rgba')) {
      color.opacity = getOpacityFromRgba(color.value);
    }
    const opacity = fixFractionalOpacityToInt(color.opacity);
    const overloadedColor = {
      reference: color.themeName,
      value: hexToRGBA(siteColor.value, normalizeOpacity(opacity)),
    };
    return overloadedColor;
  }
  return color;
}

export function overrideSiteData(
  data,
  siteTextPresets,
  siteColors,
  isSettingsPanel,
  isServiceListSettingsThemeChange = false,
) {
  if (isFont(data)) {
    return overrideSiteFontIfNeeded(
      data,
      siteTextPresets,
      isSettingsPanel,
      isServiceListSettingsThemeChange,
    );
  }

  if (isColor(data)) {
    return overrideSiteColorIfNeeded(data, siteColors);
  }

  return data;
}

export function overrideStyleProps(props) {
  const defaultOverrideData = {};
  const isMobile = isMobileFromFormFactor(props);
  const { siteTextPresets, siteColors } = props.host.style;
  let { settingsUserData, settingsDefaultData } = props;
  if (isMobile) {
    settingsDefaultData = mergeMobileDefaultsIntoDesktop(settingsDefaultData);
    settingsUserData = mergeMobileSettingsIntoDesktop(settingsUserData);
  }
  const isServiceListSettingsThemeChange =
    props.experiments['specs.bookings.ServiceListSettingsThemeChange'] ===
    'true';

  // here we prepere the data with the designer/ux overrides
  for (const key in settingsDefaultData) {
    const defaultData = settingsDefaultData[key];

    defaultOverrideData[key] = overrideSiteData(
      defaultData,
      siteTextPresets,
      siteColors,
      false,
      isServiceListSettingsThemeChange,
    );
  }

  for (const key in settingsUserData) {
    const writtenData = settingsUserData[key];
    if (writtenData) {
      settingsUserData[key] = overrideSiteData(
        writtenData,
        siteTextPresets,
        siteColors,
        false,
        isServiceListSettingsThemeChange,
      );
    }
  }

  const styleParamsData = {
    ...props.host.style.styleParams.colors,
    ...props.host.style.styleParams.fonts,
  };

  const settingsData = {
    ...settingsDefaultData, // ALL THE DATA FROM THE SITE FLAT!!
    ...defaultOverrideData, // DESINGER OVERRIDE
    ...styleParamsData, // Data from Style params (ADI preset recorder)
    ...settingsUserData, // USER DATA
  };

  const style = {
    ...props.host.style,
    styleParams: {
      colors: settingsData,
      booleans: settingsData,
      fonts: settingsData,
      numbers: settingsData,
    },
  };

  return {
    ...props,
    style,
    settingsData,
  };
}
